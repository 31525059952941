import { createContext, useContext, useRef, useState, useLayoutEffect } from "react";
import { BrowserHistory, createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import PropTypes from "prop-types";

const HistoryContext = createContext<BrowserHistory>({
    action: undefined,
    location: undefined,
    createHref: undefined,
    push: undefined,
    replace: undefined,
    go: undefined,
    back: undefined,
    forward: undefined,
    listen: undefined,
    block: undefined,
});

export const useHistory = () => useContext(HistoryContext);

const BrowserRouter = ({ children }): JSX.Element => {
    const { current: history } = useRef(createBrowserHistory({ window }));
    const [{ action, location }, setHistoryState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setHistoryState), [history]);

    return (
        <Router navigationType={action} location={location} navigator={history}>
            <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
        </Router>
    );
};

BrowserRouter.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BrowserRouter;
