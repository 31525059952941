import { createContext, useMemo } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { useUserQuery } from "src/graphql";
import getInitials from "src/utils/getInitials";
import { nameToHexColor } from "../../utils/nameToHexColor";
import useAuth from "../../hooks/useAuth";

interface User {
    isInitialized: boolean;
    id: string;
    avatar?: string;
    email?: string;
    name?: string;
    initials?: string;
    avatarColor?: string;
    tenantId?: string;
    organizationName?: string;
}

export interface UserContextValue {
    user: User;
}

interface UserProviderProps {
    children?: ReactNode;
}

const initialUser: User = {
    isInitialized: false,
    id: "",
    avatar: undefined,
    email: undefined,
    name: undefined,
    initials: undefined,
    avatarColor: undefined,
    tenantId: undefined,
    organizationName: undefined,
};

const UserContext = createContext<UserContextValue>({
    user: initialUser,
});

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

const createAvatarUrl = (base64ProfilePicture: string): string => {
    const blob = b64toBlob(base64ProfilePicture, "image/jpeg");
    return window.URL.createObjectURL(blob);
};

export const UserProvider: FC<UserProviderProps> = (props) => {
    const { children } = props;
    const { userId } = useAuth();
    const { loading, data } = useUserQuery({ variables: { userId }, skip: userId === "" || userId === undefined });

    const avatarUrl =
        data?.user.profilePicture !== undefined && data?.user.profilePicture !== ""
            ? createAvatarUrl(data.user.profilePicture as string)
            : undefined;

    const avatarColor =
        data?.user.name !== undefined && data?.user.name !== "" ? nameToHexColor(data.user.name) : undefined;

    const userContextValue: UserContextValue = useMemo(
        () => ({
            user: {
                isInitialized: !loading,
                id: userId,
                name: data?.user.name,
                initials: getInitials(data?.user.name),
                avatarColor,
                email: data?.user.email,
                avatar: avatarUrl,
                organizationName: data?.user.organization.name,
            },
        }),
        [loading, userId, data, avatarUrl, avatarColor]
    );

    return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UserContext;
