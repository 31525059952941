import useConfiguration from "./useConfiguration";

export const useAadConfig = () => {
    const { configuration } = useConfiguration();

    const AegisApiScopes: string[] = [configuration.azureAdB2C?.apiScope];

    /**
     * Scopes you add here will be prompted for user consent during sign-in.
     * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
     * For more information about OIDC scopes, visit:
     * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
     */
    const loginRequest = {
        scopes: ["openid", "offline_access"],
    };

    return {
        loginRequest,
        AegisApiScopes,
    };
};
