import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "src/store";
import { Team } from "src/graphql";

interface TeamState {
    team: Team;
}

const initialState: TeamState = {
    team: undefined,
};

const slice = createSlice({
    name: "team",
    initialState,
    reducers: {
        setActiveTeam(state: TeamState, action: PayloadAction<{ team: Team }>): void {
            const { team } = action.payload;
            state.team = team;
        },
    },
});

export const setActiveTeam =
    (team: Team): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setActiveTeam({ team }));
    };

export const { reducer } = slice;
export default slice;
