import React from "react";
import PropTypes from "prop-types";
import { ApolloClient, ApolloProvider, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import useAuth from "src/hooks/useAuth";
import { useAadConfig } from "src/hooks/useAadConfig";

const AuthorizedApolloProvider = ({ children }) => {
    const { getAccessToken } = useAuth();
    const { AegisApiScopes } = useAadConfig();

    const httpLink = createHttpLink({
        uri: `${window.location.origin}/graphql`,
    });

    const authLink = setContext(async () => {
        const token = await getAccessToken(AegisApiScopes);
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    });

    const authorizedLink = authLink.concat(httpLink);
    const unAuthorizedLink = httpLink;

    const apolloClient = new ApolloClient({
        link: ApolloLink.split(
            (operation) => operation.getContext().clientName === "unauthorized",
            unAuthorizedLink, // <= apollo will send to this if clientName is "unauthorized"
            authorizedLink // <= otherwise will send to this
        ),
        cache: new InMemoryCache(),
        connectToDevTools: true,
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthorizedApolloProvider;
