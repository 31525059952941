import { createContext, useState, useEffect, useMemo } from "react";
import type { FC, ReactNode } from "react";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

interface Configuration {
    isInitialized: boolean;
    aiInstrumentationKey: string;
    azureAdB2C: {
        tenant: string;
        frontendClientId: string;
        redirectUri: string;
        apiScope: string;
    };
}

export interface ConfigurationContextValue {
    configuration: Configuration;
}

interface ConfigurationProviderProps {
    children?: ReactNode;
}

const initialConfiguration: Configuration = {
    isInitialized: false,
    aiInstrumentationKey: "",
    azureAdB2C: undefined,
};

const ConfigurationContext = createContext<ConfigurationContextValue>({
    configuration: initialConfiguration,
});

const client = new ApolloClient({
    uri: `${window.location.origin}/frontend-config`,
    connectToDevTools: false,
    cache: new InMemoryCache(),
});

interface FrontendConfiguration {
    aiInstrumentationKey: string;
    azureAdB2C: {
        tenant: string;
        frontendClientId: string;
        redirectUri: string;
        apiScope: string;
    };
}
interface FrontendConfigurationData {
    frontendConfiguration: FrontendConfiguration;
}

export const ConfigurationProvider: FC<ConfigurationProviderProps> = (props) => {
    const { children } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<FrontendConfigurationData>();

    useEffect(() => {
        if (loading) {
            client
                .query<FrontendConfigurationData>({
                    query: gql`
                        query frontendConfiguration {
                            frontendConfiguration {
                                aiInstrumentationKey
                                azureAdB2C {
                                    tenant
                                    frontendClientId
                                    redirectUri
                                    apiScope
                                }
                            }
                        }
                    `,
                })
                .then((result) => {
                    setData(result.data);
                    setLoading(false);
                });
        }
    }, [loading]);

    const configurationContextValue: ConfigurationContextValue = useMemo(
        () => ({
            configuration: {
                isInitialized: !loading,
                aiInstrumentationKey: !loading ? data.frontendConfiguration.aiInstrumentationKey : "",
                azureAdB2C: !loading ? data.frontendConfiguration.azureAdB2C : undefined,
            },
        }),
        [loading, data]
    );

    return <ConfigurationContext.Provider value={configurationContextValue}>{children}</ConfigurationContext.Provider>;
};

export default ConfigurationContext;
