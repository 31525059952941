import React, { ReactNode, useEffect, useState } from "react";
import type { FC } from "react";
import { Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import useConfiguration from "src/hooks/useConfiguration";

interface ConfiguredMsalProviderProps {
    children: ReactNode;
}

export const ConfiguredMsalProvider: FC<ConfiguredMsalProviderProps> = (props) => {
    const { children } = props;
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
    const [initialized, setInitialized] = useState<boolean>(false);

    const { configuration } = useConfiguration();

    useEffect(() => {
        if (configuration.isInitialized) {
            const MSAL_CONFIG: Configuration = {
                auth: {
                    clientId: configuration.azureAdB2C?.frontendClientId,
                    authority: `https://${configuration.azureAdB2C.tenant}.b2clogin.com/${configuration.azureAdB2C.tenant}.onmicrosoft.com/B2C_1A_AadMultiTenantsignup_signin`,
                    knownAuthorities: [`${configuration.azureAdB2C.tenant}.b2clogin.com`],
                    redirectUri: configuration.azureAdB2C.redirectUri,
                    postLogoutRedirectUri: configuration.azureAdB2C.redirectUri,
                },
                cache: {
                    cacheLocation: "sessionStorage", // This configures where your cache will be stored
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                                default:
                                    console.log(message);
                            }
                        },
                    },
                },
            };

            setMsalInstance(new PublicClientApplication(MSAL_CONFIG));
            setInitialized(true);
        }
    }, [configuration]);

    return <>{initialized ? <MsalProvider instance={msalInstance}>{children}</MsalProvider> : null}</>;
};
