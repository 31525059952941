import React, { Suspense, lazy } from "react";
import type { RouteObject } from "react-router";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );

const LandingPage = Loadable(lazy(() => import("./components/dashboard/LandingPage")));
const DashboardLayout = Loadable(lazy(() => import("./components/dashboard/DashboardLayout/DashboardLayout")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const TeamCreate = Loadable(lazy(() => import("./pages/dashboard/TeamCreate")));
const TeamDetails = Loadable(lazy(() => import("./pages/dashboard/TeamDetails/TeamDetails")));
const TeamDashboard = Loadable(lazy(() => import("./pages/dashboard/TeamDashboard")));
const TeamEnvironments = Loadable(lazy(() => import("./pages/dashboard/TeamEnvironments")));
const TeamRequests = Loadable(lazy(() => import("./pages/dashboard/TeamRequests")));
const RequestDetails = Loadable(lazy(() => import("./pages/dashboard/RequestDetails")));
const TeamIntegrations = Loadable(lazy(() => import("./pages/dashboard/TeamIntegrations/TeamIntegrations")));
const Compliance = Loadable(lazy(() => import("./pages/dashboard/Compliance")));
const ComplianceDetails = Loadable(lazy(() => import("./pages/dashboard/ComplianceDetails")));
const TeamLog = Loadable(lazy(() => import("./pages/dashboard/TeamLog")));

const ApiBrowser = Loadable(lazy(() => import("./pages/ApiBrowser")));

const routes: RouteObject[] = [
    {
        path: "/",
        element: (
            <AuthGuard>
                <LandingPage />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <Overview />,
            },
            {
                path: "/newteam",
                element: <TeamCreate />,
            },
            {
                path: "/apibrowser",
                element: <ApiBrowser />,
            },
        ],
    },
    {
        path: ":teamSlug",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <TeamDashboard />,
            },
            {
                path: "team",
                element: <TeamDetails />,
            },
            {
                path: "environments",
                element: <TeamEnvironments />,
            },
            {
                path: "compliance",
                element: <Compliance />,
            },
            {
                path: "compliance/:complianceType",
                element: <ComplianceDetails />,
            },
            {
                path: "requests",
                element: <TeamRequests />,
            },
            {
                path: "requests/:requestId",
                element: <RequestDetails />,
            },
            {
                path: "integrations",
                element: <TeamIntegrations />,
            },
            {
                path: "log",
                element: <TeamLog />,
            },
        ],
    },
];

export default routes;
