import { createContext, useEffect, useMemo, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useAadConfig } from "src/hooks/useAadConfig";

interface State {
    isInitialized: boolean;
    tenantId: string;
    userId: string;
}

interface AuthContextValue extends State {
    login: () => void;
    logout: () => Promise<void>;
    getAccessToken: (scopes: string[], forceRefresh?: boolean) => Promise<string>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: "INITIALIZE";
    payload: {
        isAuthenticated: boolean;
        tenantId: string;
        userId: string;
    };
};

type LoginAction = {
    type: "LOGIN";
    payload: {
        tenantId: string;
        userId: string;
    };
};

type LogoutAction = {
    type: "LOGOUT";
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialState: State = {
    isInitialized: false,
    tenantId: "",
    userId: "",
};

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { tenantId, userId } = action.payload;
        return {
            ...state,
            isInitialized: true,
            tenantId,
            userId,
        };
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { tenantId, userId } = action.payload;
        return {
            ...state,
            tenantId,
            userId,
        };
    },
    LOGOUT: (state: State): State => ({
        ...state,
    }),
};

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    login: () => null,
    logout: () => Promise.resolve(),
    getAccessToken: () => Promise.resolve(""),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const { instance: msalApp, accounts } = useMsal();
    const { loginRequest } = useAadConfig();

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                if (accounts && accounts.length > 0) {
                    let acc;
                    if (accounts.length > 1) {
                        // Add choose account code here
                        [acc] = accounts;
                    } else if (accounts.length === 1) {
                        [acc] = accounts;
                    }

                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                            tenantId: acc.tenantId,
                            userId: acc.idTokenClaims.ooid,
                        },
                    });
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            tenantId: "",
                            userId: "",
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        tenantId: "",
                        userId: "",
                    },
                });
            }
        };

        initialize();
    }, [accounts]);

    const authContextValue: AuthContextValue = useMemo(() => {
        const loginWithRedirect = (): void => {
            try {
                msalApp.loginRedirect(loginRequest);
            } catch (error) {
                console.error(error);
            }
        };

        const logout = async (): Promise<void> => {
            await msalApp.logoutRedirect();

            dispatch({
                type: "LOGOUT",
            });
        };

        const getAccessToken = async (scopes: string[], forceRefresh?: boolean): Promise<string> => {
            let token: string;
            const request = {
                scopes,
                account: {
                    tenantId: state.tenantId,
                    environment: (accounts[0] as any).environment,
                    homeAccountId: accounts[0].homeAccountId ?? "",
                    username: accounts[0].username ?? "",
                    localAccountId: accounts[0].localAccountId ?? "",
                },
                forceRefresh: forceRefresh === true,
            };
            try {
                const response = await msalApp.acquireTokenSilent(request);

                // In some cases msal returns no or an empty token in B2C scenarios, in that case we need some user interaction.
                // Ref: https://github.com/Azure-Samples/ms-identity-b2c-javascript-spa/blob/ec20b22817a7d5548ae6337d4f9cfec819a46e33/App/authPopup.js#L96
                if (!response.accessToken || response.accessToken === "") {
                    throw new InteractionRequiredAuthError();
                }

                token = response.accessToken;
            } catch (e) {
                if (e instanceof InteractionRequiredAuthError) {
                    const response = await msalApp.acquireTokenPopup(request);
                    token = response.accessToken;
                } else {
                    console.error("Error acquiring token", e);
                }
            }

            return token;
        };

        return {
            ...state,
            login: loginWithRedirect,
            logout,
            getAccessToken,
        };
    }, [accounts, loginRequest, msalApp, state]);

    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
