import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { FeatureToggles } from "@paralleldrive/react-feature-toggles";
import "./i18n";
import GlobalStyles from "./components/GlobalStyles";
import RTL from "./components/RTL";
import SettingsDrawer from "./components/SettingsDrawer";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createAegisTheme } from "./theme";
import useConfiguration from "./hooks/useConfiguration";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const App: FC = () => {
    const content = useRoutes(routes);
    const { settings } = useSettings();
    const { configuration } = useConfiguration();
    const auth = useAuth();
    useScrollReset();

    const theme = createAegisTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme,
    });

    const activeFeatures = ["frontend-demoui"];

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <FeatureToggles features={activeFeatures}>
                    <RTL direction={settings.direction}>
                        <SnackbarProvider dense maxSnack={3}>
                            <GlobalStyles />
                            <SettingsDrawer />
                            {auth.isInitialized && configuration.isInitialized ? content : <SplashScreen />}
                        </SnackbarProvider>
                    </RTL>
                </FeatureToggles>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
