import React, { FC, ReactNode, useEffect } from "react";
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import useConfiguration from "src/hooks/useConfiguration";
import {
    ApplicationInsights,
    DistributedTracingModes,
    IDependencyTelemetry,
    ITelemetryItem,
    RemoteDependencyData,
} from "@microsoft/applicationinsights-web";
import { useHistory } from "./RouterContext";

interface AppInsightsProviderProps {
    children?: ReactNode;
}

const reactPlugin = new ReactPlugin();

const telemetryInitializer = (envelope: ITelemetryItem): boolean => {
    // Set human readable name to display in App Insights
    envelope.tags["ai.cloud.role"] = "Frontend";

    // Filter out dependency calls that we don't want to see in App Insights
    if (envelope.name === RemoteDependencyData.envelopeType) {
        const dependencyData = envelope.baseData as IDependencyTelemetry;
        if (dependencyData.target.includes("b2clogin.com")) {
            return false;
        }
    }

    return true;
};

export const AppInsightsProvider: FC<AppInsightsProviderProps> = (props) => {
    const { children } = props;
    const { configuration } = useConfiguration();
    const { aiInstrumentationKey, isInitialized: isConfigurationInitialized } = configuration;
    const history = useHistory();

    useEffect(() => {
        if (isConfigurationInitialized && aiInstrumentationKey !== "") {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: aiInstrumentationKey,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history },
                    },
                    disableFetchTracking: false,
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    disableTelemetry: window.location.hostname === "localhost",
                },
            });
            appInsights.loadAppInsights();
            appInsights.addTelemetryInitializer(telemetryInitializer);
        }
    }, [aiInstrumentationKey, isConfigurationInitialized, history]);

    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};
