import { useState } from "react";
import type { FC } from "react";
import { Box, Button, FormHelperText } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

const LoginAad: FC = (props) => {
    const isMountedRef = useIsMountedRef();
    const { login } = useAuth();
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async (): Promise<void> => {
        try {
            login();
        } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
                setError(err.message);
            }
        }
    };

    return (
        <div {...props}>
            {error && (
                <Box sx={{ my: 3 }}>
                    <FormHelperText error>{error}</FormHelperText>
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button color="primary" onClick={handleLogin} variant="contained">
                    Log In
                </Button>
            </Box>
        </div>
    );
};

export default LoginAad;
