import type { FC } from "react";
import type { Theme } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import PropTypes from "prop-types";

interface LogoProps {
    sx?: SxProps<Theme>;
    height: number;
    fill: string;
}

const LogoRoot = styled("svg")({});

const Logo: FC<LogoProps> = ({ sx, height, fill }) => (
    <LogoRoot version="1.1" viewBox="0 0 370 310" sx={[() => ({ height, fill }), () => ({ ...sx })]}>
        <title>Project Aegis</title>
        <g>
            <path d="M199.458 291.413C191.63 303.982 173.332 303.982 165.504 291.413L147.827 263.03L161.434 241.072C167.253 231.683 167.253 216.461 161.434 207.072L124.102 146.829C118.284 137.44 108.85 137.44 103.032 146.829L89.2794 169.021L3.0551 30.573C-5.24179 17.251 4.33745 2.7603e-05 20.0319 2.6331e-05L344.93 0C360.624 -1.27194e-06 370.204 17.251 361.907 30.573L278.966 163.748L196.51 30.6912C190.692 21.3021 181.258 21.3021 175.44 30.6912L138.107 90.9335C132.289 100.322 132.289 115.545 138.107 124.934L220.418 257.757L199.458 291.413Z" />
        </g>
    </LogoRoot>
);

Logo.propTypes = {
    sx: PropTypes.object,
    height: PropTypes.number,
    fill: PropTypes.string,
};

export default Logo;
