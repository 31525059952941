import { useEffect } from "react";
import type { FC, ReactNode } from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
// This currently generates a warning. Waiting for fix: https://github.com/facebook/create-react-app/pull/11752
import stylisRTLPlugin from "stylis-plugin-rtl";

interface RTLProps {
    children: ReactNode;
    direction: "ltr" | "rtl";
}

const styleCache = () =>
    createCache({
        key: "rtl",
        prepend: true,
        stylisPlugins: [stylisRTLPlugin],
    });

const RTL: FC<RTLProps> = (props) => {
    const { children, direction } = props;

    useEffect(() => {
        document.dir = direction;
    }, [direction]);

    if (direction === "rtl") {
        return <CacheProvider value={styleCache()}>{children}</CacheProvider>;
    }

    return <>{children}</>;
};

export default RTL;
