import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { createRoot } from "react-dom/client";
import BrowserRouter from "./contexts/RouterContext";
import App from "./App";
import { AuthProvider } from "./contexts/AadContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { ConfiguredMsalProvider } from "./contexts/ConfiguredMsalContext";
import AuthorizedApolloProvider from "./lib/AuthorizedApolloProvider";
import { ConfigurationProvider } from "./contexts/ConfigurationContext";
import { AppInsightsProvider } from "./contexts/AppInsightsContext";
import { UserProvider } from "./contexts/user/UserContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <StrictMode>
        <BrowserRouter>
            <ConfigurationProvider>
                <AppInsightsProvider>
                    <HelmetProvider>
                        <ReduxProvider store={store}>
                            <StyledEngineProvider injectFirst>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <ConfiguredMsalProvider>
                                        <SettingsProvider>
                                            <AuthProvider>
                                                <AuthorizedApolloProvider>
                                                    <UserProvider>
                                                        <App />
                                                    </UserProvider>
                                                </AuthorizedApolloProvider>
                                            </AuthProvider>
                                        </SettingsProvider>
                                    </ConfiguredMsalProvider>
                                </LocalizationProvider>
                            </StyledEngineProvider>
                        </ReduxProvider>
                    </HelmetProvider>
                </AppInsightsProvider>
            </ConfigurationProvider>
        </BrowserRouter>
    </StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
