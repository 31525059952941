import type { FC } from "react";
import { Helmet } from "react-helmet-async";

import { Box, Card, CardContent, Container, Divider, useTheme } from "@mui/material";
import { LoginAad } from "../../components/authentication/login";
import Logo from "../../components/logo/Logo";
import LogoFullWithTagLine from "../../components/logo/LogoFullWithTagLine";

const Login: FC = () => {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>Login | Project Aegis</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                <Container maxWidth="sm" sx={{ py: "80px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 8,
                        }}
                    >
                        <Logo height={20} fill={theme.palette.text.primary} />
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                p: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3,
                                }}
                            >
                                <LogoFullWithTagLine height={100} fill={theme.palette.text.primary} />
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3,
                                }}
                            >
                                <LoginAad />
                            </Box>
                            <Divider sx={{ my: 3 }} />
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
